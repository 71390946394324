.loading-page {
    width: 100vw;
    height: 100vh;
}

.logo {
  width: 32px;
  height: 32px;
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
}

.logo svg {
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: calc(50% - 64px);
  left: calc(50% - 64px);;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  perspective: 800px;
  transform-origin: center;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;  
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 0.6s linear infinite;
  border-bottom: 6px solid #39FF14;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 0.6s linear infinite;
  border-right: 6px solid #39FF14;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 0.6s linear infinite;
  border-top: 6px solid #39FF14;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}