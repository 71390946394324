.menu_container {
	display: flex;
	flex-direction: column;
	width: 30px;
	cursor: pointer;
	position: relative;
	z-index: 1250;
	align-items: flex-end;
	/* background-color: white; */
}

.menu_container span {
	background: #39FF14;
	border-radius: 5px;
	height: 3px;
	margin: 3px 0;
	transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.menu_container span:nth-of-type(1) {
	width: 50%;
	/* background-color: rgba(255, 0, 0, 0.3); */
}

.menu_container span:nth-of-type(2) {
	width: 100%;
	/* background-color: rgba(0, 128, 0, 0.3); */
}

.menu_container span:nth-of-type(3) {
	width: 75%;
	/* background-color: rgba(0, 0, 255, 0.3); */
}

.menu_container input[type='checkbox'] {
	display: none;
}

.menu_container input[type='checkbox']:checked ~ span:nth-of-type(1) {
	transform-origin: bottom;
	transform: rotatez(-45deg) translate(-3.5px, 0px);
}

.menu_container input[type='checkbox']:checked ~ span:nth-of-type(2) {
	transform-origin: top;
	transform: rotatez(45deg);
}

.menu_container input[type='checkbox']:checked ~ span:nth-of-type(3) {
	transform-origin: bottom;
	width: 50%;
	transform: translate(-13px, -5px) rotatez(-45deg);
}
