:root {
	--primary-color: #39ff14;

	--cube-size: 350px;
	--cube-rotation-time: 6s;
    --cube-rotation-delay: 0s;
	--cube-light-on-delay: 1.8s;
	--cube-light-on-duration: 1s;

	--cube-top-light-on: #202020;
	--cube-top-light-off: #191919;

	--cube-light-on: linear-gradient(#191919, var(--primary-color));
	--cube-light-off: linear-gradient(
		45deg,
		rgba(21, 21, 21, 1) 40%,
		rgba(24, 24, 24, 1) 100%
	);

	--cube-reflection-on: radial-gradient(
		circle,
		var(--primary-color) 0%,
		rgba(57, 255, 20, 0.5) 100%
	);
	--cube-reflection-off: transparent;
	--cube-reflection-aura-on: 0 0 100px var(--primary-color);
	--cube-reflection-aura-off: none;
}

.cube {
	width: var(--cube-size);
	height: var(--cube-size);
	position: relative;
	transform-style: preserve-3d;
	transform: rotateX(-30deg) rotateY(45deg);
	animation: rotate-cube var(--cube-rotation-time) linear var(--cube-rotation-delay) infinite;
}

.cube div {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
}

.cube div span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--cube-light-off);
	transform: rotateY(calc(90deg * var(--i)))
		translateZ(calc(var(--cube-size) / 2));
	animation: cube-light-on var(--cube-light-on-duration) ease
		var(--cube-light-on-delay) forwards;
}

.top {
	display: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: rotateX(90deg) translateZ(calc(var(--cube-size) / 2));
	background: var(--cube-top-light-off);
	animation: cube-light-on-top var(--cube-light-on-duration) ease
		var(--cube-light-on-delay) forwards;
}

.top::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--cube-reflection-off);
	transform: translateZ(calc(var(--cube-size) * -1.33));
	filter: blur(20px);
	animation: cube-reflection-on var(--cube-light-on-duration) ease
		var(--cube-light-on-delay) forwards;
}

@keyframes rotate-cube {
	0% {
		transform: rotateX(-30deg) rotateY(45deg);
	}
	100% {
		transform: rotateX(-30deg) rotateY(405deg);
	}
}

@keyframes cube-light-on {
	10%,
	25%,
	40%,
	55%,
	70%,
	80% {
		background: var(--cube-light-off);
	}
	0%,
	15%,
	30%,
	47%,
	62%,
	75%,
	100% {
		background: var(--cube-light-on);
	}
}

@keyframes cube-light-on-top {
	10%,
	25%,
	40%,
	55%,
	70%,
	80% {
		background: var(--cube-top-light-off);
	}
	0%,
	15%,
	30%,
	47%,
	62%,
	75%,
	100% {
		background: var(--cube-top-light-on);
	}
}

@keyframes cube-reflection-on {
	5%,
	25%,
	40%,
	55%,
	70%,
	80% {
		background: var(--cube-reflection-off);
		box-shadow: var(--cube-reflection-aura-off);
	}
	0%,
	15%,
	30%,
	47%,
	62%,
	75%,
	100% {
		background: var(--cube-reflection-on);
		box-shadow: var(--cube-reflection-aura-on);
	}
}


@media screen and (max-width: 1400px) {
	:root {
		--cube-size: 300px
	}
}

@media screen and (max-width: 1200px) {
	:root {
		--cube-size: 250px
	}
}
